<template>
  <div>
    <!-- Begin footer -->
    <footer class="footer footer-minimal">
      <div class="footer-main display-none">
        <div class="container">
          <div class="row items align-items-center">
            <div class="col-lg-3 col-md-4 col-12 item">
              <!-- Begin company info -->
              <div class="footer-company-info">
                <div class="footer-company-top">
                  <a href="/" class="logo logo-ico-widht-text" title="Objectpower">
                    <img
                      class="lazy logo-ico mb-1 mr-1"
                      src="/img/logo_trans.png"
                      width="36"
                      height="35"
                      alt
                    />
                    <span class="logo-text">bjectPower</span>
                  </a>
                </div>
              </div>
              <!-- End company info -->
            </div>
            <!-- <div class="col-md col-12 item">
              <div class="footer-item">
                <nav class="footer-nav">
                  <ul class="footer-mnu footer-mnu-line">
                    <li>
                      <router-link to="what-we-do" class="hover-link" data-title="What we do">
                        <span>What we do</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="cloud" class="hover-link" data-title="Cloud">
                        <span>Cloud</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="develop" class="hover-link" data-title="Develop">
                        <span>Develop</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="work" class="hover-link" data-title="Work">
                        <span>Work</span>
                      </router-link>
                    </li>
                    <li>
                      <router-link to="contact" class="hover-link" data-title="Get in touch">
                        <span>Get in touch</span>
                      </router-link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>-->
            <div class="col-md col-12 item">
              <ul class="footer-contacts footer-mnu-line justify-content-end">
                <li>
                  <i class="material-icons md-22 footer-contact-email">email</i>
                  <div class="footer-contact-info">
                    <a href="mailto:hello@objectpower.co.uk">hello@objectpower.co.uk</a>
                  </div>
                </li>
                <!-- <li>
                  <i class="material-icons md-22 footer-contact-tel">meeting_room</i>
                  <div class="footer-contact-info">
                    <router-link
                      to="#!"
                      class="formingHrefTel"
                      title="Conference DDI"
                    >+44 1273 929903</router-link>
                  </div>
                </li> -->
                <li>
                  <i class="material-icons md-22 footer-contact-tel">smartphone</i>
                  <div class="footer-contact-info">
                    <router-link to="#!" class="formingHrefTel" title="Company DDI">+44 1273 524307</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="row mt-4">
            <!--      <div class="col">
              <ul class="footer-contacts footer-mnu-line justify-content-start">
                <li>
                  <i class="material-icons md-22 footer-contact-email">email</i>
                  <div class="footer-contact-info">
                    <a href="mailto:hello@objectpower.co.uk">hello@objectpower.co.uk</a>
                  </div>
                </li>
                <li>
                  <i class="material-icons md-22 footer-contact-tel">meeting_room</i>
                  <div class="footer-contact-info">
                    <router-link
                      to="#!"
                      class="formingHrefTel"
                      title="Conference DDI"
                    >+44 1273 929903</router-link>
                  </div>
                </li>
                <li>
                  <i class="material-icons md-22 footer-contact-tel">smartphone</i>
                  <div class="footer-contact-info">
                    <router-link to="#!" class="formingHrefTel" title="Company DDI">+44 1273 524307</router-link>
                  </div>
                </li>
              </ul>
            </div>-->
          </div>
        </div>
      </div>
      <div class="footer-bottom pt-4 pb-0">
         <div class="container">
          <div class="row items align-items-center mt-0 pt-0 pb-4">
            <div class="col-lg-3 col-md-4 col-12 item">
              <!-- Begin company info -->
              <div class="footer-company-info">
                <div class="footer-company-top">
                  <a href="/" class="logo logo-ico-widht-text" title="Objectpower">
                    <img
                      class="lazy logo-ico mb-1 mr-1"
                      src="/img/logo_trans.png"
                      width="36"
                      height="35"
                      alt
                    />
                    <span class="logo-text">bjectPower</span>
                  </a>
                </div>
              </div>
              <!-- End company info -->
            </div>
       
            <div class="col-md col-12 item">
              <ul class="footer-contacts footer-mnu-line justify-content-end">
                <li>
                  <i class="material-icons md-22 footer-contact-email">email</i>
                  <div class="footer-contact-info">
                    <a href="mailto:hello@objectpower.co.uk">hello@objectpower.co.uk</a>
                  </div>
                </li>
                <!-- <li>
                  <i class="material-icons md-22 footer-contact-tel">meeting_room</i>
                  <div class="footer-contact-info">
                    <router-link
                      to="#!"
                      class="formingHrefTel"
                      title="Conference DDI"
                    >+44 1273 929903</router-link>
                  </div>
                </li> -->
                <li>
                  <i class="material-icons md-22 footer-contact-tel">smartphone</i>
                  <div class="footer-contact-info">
                    <router-link to="#!" class="formingHrefTel" title="Company DDI">+44 1273 524307</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>        
        </div>
        <div class="container">
          <div class="row justify-content-between items mb-0 pb-0" >
            <div class="col-md-auto col-12 item">
              <nav class="footer-links">
                <ul>
                  <li>
                    <router-link to="contact">Get in touch</router-link>
                  </li>
                    <li>
                    <router-link to="privacy-policy">Privacy Policy</router-link>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="col-md-auto col-12 item">
              <div
                class="copyright"
              >&copy; {{ new Date().getFullYear() }} ObjectPower. All rights reserved.</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- End footer -->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>